
import { PlanInput, Project } from '@/api/interfaces'
import { usePlanRepository } from '@/api/repository/plan-repository'
import { useProjectRepository } from '@/api/repository/project-repository'
import { useTeamRepository } from '@/api/repository/team-repository'
import { Rights } from '@/api/rights'
import { useGetUsersBasic, UserBasic } from '@/api/users'
import CurrencyInput from '@/components/common/currency-input.vue'
import DateInput from '@/components/common/date-input.vue'
import { useAuthGetters, useNotify } from '@/store'
import { useFormatUser } from '@/utils/format/format-user'
import { useValidation, ValidationForm } from '@/utils/validation'
import { computed, defineComponent, nextTick, onMounted, ref, watch } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify'

interface ControlForm {
  project: Project
  users: UserBasic[]
  from: string
  to: string
  hoursBudget: number | null
  billable: boolean
}

export default defineComponent({
  name: 'plan-create',
  components: { DateInput, CurrencyInput },
  setup: (_, { root }) => {
    const breadcrumbs = [
      {
        text: root.$tc('plan.title', 2),
        to: { name: 'projectPlans' },
        exact: true,
      },
      {
        text: root.$t('plan.create'),
      },
    ]

    const controlFormRef = ref<ValidationForm | null>()
    const controlFormValid = ref(false)
    const controlFormInit = {
      project: {} as Project,
      users: [],
      from: '',
      to: '',
      hoursBudget: null,
      billable: true,
    }
    const controlForm = ref<ControlForm>({ ...controlFormInit })
    watch(
      () => controlForm.value.project,
      (project) => {
        if (!project.from || !project.to) {
          controlForm.value.from = ''
          controlForm.value.to = ''
        } else {
          controlForm.value.from = project.from
          controlForm.value.to = project.to
        }
      }
    )

    const plansFormRef = ref<ValidationForm | null>()
    const plansFormValid = ref(false)

    const dateInputFromOptions = computed(() => {
      const options = new Map()
      if (controlForm.value.project) {
        options.set('min', controlForm.value.project.from)
        options.set('max', controlForm.value.to)
      }

      return Object.fromEntries(options)
    })

    const dateInputToOptions = computed(() => {
      const options = new Map()
      if (controlForm.value.project) {
        options.set('min', controlForm.value.from)
        options.set('max', controlForm.value.project.to)
      }

      return Object.fromEntries(options)
    })

    function resetControlForm() {
      controlFormRef.value?.resetValidation()
      controlForm.value = { ...controlFormInit }
    }

    const { useGetAllForUser: useGetAllProjects } = useProjectRepository()
    const { data: projects, getAllForUser: getAllProjects } = useGetAllProjects()

    const { data: usersBasic, getUsersBasic: getAllUsersBasic } = useGetUsersBasic()

    const { useGetAll: useGetAllTeams } = useTeamRepository()
    const { data: teams, getAll: getAllTeams } = useGetAllTeams()

    const { formatUser } = useFormatUser()

    const { hasRight } = useAuthGetters()
    const canReadRoles = computed(() => hasRight.value(Rights.ROLE_READ))

    function getFullUserNameWithRole(user: UserBasic) {
      return `${formatUser(user)} ${
        canReadRoles.value && user.roles ? `(${root.$t(`roles.names.${user.roles.map((role) => role.name)}`)})` : ''
      }`
    }

    const plans = ref<PlanInput[]>([])

    function addPlans() {
      if (!controlFormRef.value?.validate()) return
      const { users, project, hoursBudget, ...fields } = controlForm.value
      users.forEach((user) => {
        plans.value.push({
          costs: user.price ?? 0,
          hoursStepSize: 0.5,
          projectId: project?.id ?? 0,
          sellingPrice: 0,
          userId: user.id,
          comment: '',
          teamId: user.teamId,
          hoursBudget: hoursBudget ?? 0,
          ...fields,
        })
      })
      controlForm.value = { ...controlFormInit, project: controlForm.value.project }
      controlFormRef.value.resetValidation()
      nextTick(() => {
        plansFormRef.value?.validate()
      })
    }

    function removePlan(index: number) {
      plans.value.splice(index, 1)
    }

    const tableHeaders: DataTableHeader[] = [
      {
        text: root.$t('form.field.user').toString(),
        value: 'userId',
        width: '200px',
        sortable: false,
      },
      {
        text: root.$t('form.field.team').toString(),
        value: 'teamId',
        width: '200px',
        sortable: false,
      },
      {
        text: root.$t('form.field.from').toString(),
        value: 'from',
        width: '120px',
        sortable: false,
      },
      {
        text: root.$t('form.field.to').toString(),
        value: 'to',
        width: '120px',
        sortable: false,
      },
      {
        text: root.$t('form.field.costs').toString(),
        value: 'cost',
        width: '120px',
        sortable: false,
      },
      {
        text: root.$t('form.field.sellingPrice').toString(),
        value: 'sellingPrice',
        width: '120px',
        sortable: false,
      },
      {
        text: root.$t('form.field.budget').toString(),
        value: 'hoursBudget',
        width: '100px',
        sortable: false,
      },
      {
        text: root.$t('form.field.comment').toString(),
        value: 'comment',
        width: '150px',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        width: '100px',
        sortable: false,
        align: 'end',
      },
    ]

    const { useSave: useSavePlan } = usePlanRepository()
    const { save } = useSavePlan()

    const { addNotification } = useNotify()

    async function submitPlans() {
      if (!plansFormRef.value?.validate()) return
      const promises: Promise<PlanInput>[] = []
      plans.value.forEach((plan) => promises.push(save(plan)))
      const requests = await Promise.allSettled(promises)
      plans.value = plans.value.filter((plan, index) => requests[index].status === 'rejected')
      if (plans.value.length === 0) {
        addNotification({
          text: root.$t('form.save.success').toString(),
          type: 'success',
        })
      }
      if (plans.value.length > 0) {
        addNotification({
          text: root.$t('form.save.partialError').toString(),
          type: 'warning',
        })
      }
    }

    function resetPage() {
      plans.value = []
      resetControlForm()
    }

    onMounted(() => {
      getAllProjects({ params: { noChildren: true, page: 0, size: 9999, projectStatus: 'ACTIVE' } })
      getAllUsersBasic({ page: 0, size: 9999 }, { active: true })
      getAllTeams({ params: { page: 0, size: 9999 } })
    })

    return {
      breadcrumbs,
      controlFormRef,
      controlFormValid,
      controlForm,
      resetControlForm,
      dateInputFromOptions,
      dateInputToOptions,
      projects,
      usersBasic,
      getFullUserNameWithRole,
      plans,
      addPlans,
      removePlan,
      teams,
      tableHeaders,
      plansFormRef,
      plansFormValid,
      submitPlans,
      resetPage,
      formatUser,
      ...useValidation(),
    }
  },
})
